import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/threatcart.css"
import SpinnerLoader from '../components/SpinnerLoader'
import Modal from 'react-bootstrap/Modal';
import * as FaIcons from 'react-icons/fa';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Papa from "papaparse";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {VendorObject} from "../common/types";
import {VulnerabilitiesObject} from "../common/types";
import {UserCvesObject} from "../common/types";

import { NOTFOUND } from 'dns';


const cveInitial = {
    userID: "",
    cveID: "",
    vendorProduct: "",
    cveWorked: true,
    networkFirewallStatus: "doesnotapply",
    networkFirewallComment: "",
    networkIDSIPSStatus: "doesnotapply",
    networkIDSIPSComment: "",
    networkWebStatus: "doesnotapply",
    networkWebComment: "",
    networkEmailStatus: "doesnotapply",
    networkEmailComment: "",
    endpointEDRStatus: "doesnotapply",
    endpointEDRComment: "",
    endpointIPSStatus: "doesnotapply",
    endpointIPSComment: "",
    endpointFirewallStatus: "doesnotapply",
    endpointFirewallComment: "",
    endpointWebStatus: "doesnotapply",
    endpointWebComment: "",
    appSIEMStatus: "doesnotapply",
    appSIEMComment: "",
    appEDRStatus: "doesnotapply",
    appEDRComment: "",
    appMitigationConfigStatus: "doesnotapply",
    appMitigationConfigComment: "",
    appMitigationPatchingStatus: "doesnotapply",
    appMitigationPatchingComment: ""
}

const Vendor: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [vendorObject, setVendorObject] = useState<VendorObject[]>([]);
    const [vendorUserObject, setVendorUserObject] = useState<VendorObject[]>([]);
    const [vulnerabilitiesObject, setVulnerabilitiesObject] = useState<VulnerabilitiesObject[]>([]);
    const [userVendorCvesObject, setUserVendorCvesObject] = useState<UserCvesObject[]>([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingVendor, setLoadingVendor] = useState(false);
    const [deleteVendor, setDeleteVendor] = useState("");
    const [templateName, setTemplateName] = useState("nessus");
    const [uploadedFile, setUploadedFile] = useState<any[]>([]);
    const fileReader = new FileReader();
    const [fileUploadStatus, setFileUploadStatus] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setDeleteVendor("")};
    const handleShow = () => setShow(true);

    let domain = domainHook();

    const getVendors = async(userVendors: VendorObject[]) => {
        setLoadingVendor(true);
            fetch(`${domain}/Vendor/GetVendorsAll` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data: VendorObject[]) => {
                const newArray = data.filter(({ vendorProject }) => !userVendors.some((e) => e.vendorProject === vendorProject))
                setVendorObject(newArray);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingVendor(false);
            });
    }

    const getUserVendorCves = async(userID: string) => {
        //setLoadingUser(true);
            fetch(`${domain}/Cve/GetVulnerabilitiesByUserId?userID=${userID}` ,{method: 'GET',})
                .then((response) => response.json())
                .then((data) => {
                    setUserVendorCvesObject(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });
    }

    const getUserVendors = async(userID: string) => {
        setLoadingUser(true);
            fetch(`${domain}/Vendor/GetVendorsByUser?userID=${userID}` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data) => {
                setVendorUserObject(data);
                getVendors(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingUser(false);
            });
    }

    const insertLog = async(logItem: string, logText : string) => {

        const date = new Date();
        date.setHours(date.getHours() - 4);
        var jsonObject = { userID : userObject?.userID ,changeItem : logItem, changeText : logText, changeDate: date};

        let updateObjectJson = JSON.parse(JSON.stringify(jsonObject));

        fetch(`${domain}/ChangeLog/InsertChangeLog?changeLogData=${JSON.stringify(updateObjectJson)}` ,{method: 'PUT',})
        .then((response) => response.json())
        .then((data) => {
            //setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            //setLoadingUser(false);
        });
    }

    const insertVendorUser = async(vendorName: string) => {
        fetch(`${domain}/Vendor/InsertVendorsUser?userID=${userObject?.userID}&vendorName=${vendorName}` ,{method: 'POST',})
        .then((response) => response.json())
        .then((data) => {
            getUserVendors(userObject?.userID == null ? "" : userObject?.userID);
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
            setLoadingUser(false);
        });
    }

    const deleteVendorUser = async(vendorName: string) => {
        fetch(`${domain}/Vendor/DeleteVendorsUser?userID=${userObject?.userID}&vendorName=${vendorName}` ,{method: 'DELETE',})
        .then((response) => response.json())
        .then((data) => {
            getUserVendors(userObject?.userID == null ? "" : userObject?.userID);
      }).catch((err) => {
          console.log(err);
      })
      .finally(() => {
          setLoadingUser(false);
      });
      insertLog("Vendor Removed", `${vendorName}`);
    }

    const removeVendor = async(vendorName : string) => {
        setDeleteVendor(vendorName);
        handleShow();
    }

    const cveAddInitial = async(vendor: string, cveId: string) => {

        cveInitial.cveID = cveId;
        cveInitial.vendorProduct = vendor;
        cveInitial.userID = userObject === null ? "" : userObject?.userID === undefined ? "" : userObject?.userID;

        if(cveInitial.userID !=""){
            fetch(`${domain}/Cve/UpdateCVEProtection?userID=${userObject?.userID}&cveData=${JSON.stringify(cveInitial)}` ,{method: 'PUT',})
            .then((response) => response.json())
            .then((data) => {
                //setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });

        }

    }

    const fileSelected = (e:any) => {
        setFileUploadStatus("");
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                let fileResult:any = [];
                fileResult = results.data;
                if(fileResult!= null){
                    setUploadedFile(fileResult);
                }
            },
        });

        getUserVendorCves(userObject?.userID!);

        fetch(`${domain}/Vulnerabilities/GetAllVulnerabilities` ,{method: 'GET',})
        .then((response) => response.json())
        .then((data) => {
            setVulnerabilitiesObject(data);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoadingUser(false);
        });
    };

    const uploadFile = (e:any) => {
        var errorText = "";

        e.preventDefault();
        var fileVendorList: any[] = [];

        if (uploadedFile) {
            if(templateName === "nessus") {
                if (uploadedFile.some(item => item.hasOwnProperty('definition.cve'))) {
                    uploadedFile.forEach((cve:any, index) => {
                        cve['cve'] = cve['definition.cve']; 
                        // Delete unnecessary keys
                        delete cve['definition.cve'];
                        delete cve['asset.name'];
                        delete cve['definition.family'];
                    });
                    const result = uploadedFile.filter(o => Object.values(o).some(v => v !== ""));
    
                    result.map((data:any) => {
                        const cveList = data.cve.includes(',') ? data.cve.split(",") : data.cve;
                        cveList.map((cveFromList: any) => {
                            if(vulnerabilitiesObject.find((o) => o.cveID === cveFromList)) {
                                let vObject = vulnerabilitiesObject.find((o) => o.cveID === cveFromList);
                                let vul = {} as VulnerabilitiesObject;
                                vul.cveID = vObject?.cveID === undefined ? "" : vObject?.cveID;
                                vul.vendorProject = vObject?.vendorProject === undefined ? "" : vObject?.vendorProject;
                                fileVendorList.push(vul);
                            }
                            else {
                                console.log("Not in Cisa: " + cveFromList);
                            }
                        })
                    });
                } else {
                    errorText = "Error: Incorrect Nessus file format";
                }
        } else if(templateName === "rapid7") {
            if (uploadedFile.some(item => item.hasOwnProperty('Vulnerability CVE IDs'))) {
                uploadedFile.forEach((cve:any, index) => {
                    const cveList = cve['Vulnerability CVE IDs'].split(",");
                    cveList.map((cveFromList: any) => {
                        if(vulnerabilitiesObject.find((o) => o.cveID === cveFromList)) {
                            let vObject = vulnerabilitiesObject.find((o) => o.cveID === cveFromList);
                            let vul = {} as VulnerabilitiesObject;
                            vul.cveID = vObject?.cveID === undefined ? "" : vObject?.cveID;
                            vul.vendorProject = vObject?.vendorProject === undefined ? "" : vObject?.vendorProject;
                            fileVendorList.push(vul);
                        }
                        else {
                            console.log("Not in Cisa: " + cveFromList);
                        }
                    })
                });
            } else {
                errorText = "Error: Incorrect Rapid7 file format";
            }
        } else if(templateName === "qualys") {
            if (uploadedFile.some(item => item.hasOwnProperty('CVE ID'))) {
                uploadedFile.forEach((cve:any, index) => {
                    const cveList = cve['CVE ID'].split(",");
                    cveList.map((cveFromList: any) => {
                        if(vulnerabilitiesObject.find((o) => o.cveID === cveFromList)) {
                            let vObject = vulnerabilitiesObject.find((o) => o.cveID === cveFromList);
                            let vul = {} as VulnerabilitiesObject;
                            vul.cveID = vObject?.cveID === undefined ? "" : vObject?.cveID;
                            vul.vendorProject = vObject?.vendorProject === undefined ? "" : vObject?.vendorProject;
                            fileVendorList.push(vul);
                        }
                        else {
                            console.log("Not in Cisa: " + cveFromList);
                        }
                    })
                });
            } else {
                errorText = "Error: Incorrect Qualys file format";
            }
        }
    } else {
        console.log("No File to Upload");
    }

    if(errorText !=""){
        setFileUploadStatus(errorText);
    } else {
        var vendorsToAdd: any[] = [];
        var vendorsInSystem: any[] = [];

        //Check list and split based on if vendors exist already in user list or not
        fileVendorList.map((data:any) => {
            let vul = {} as VulnerabilitiesObject;
            vul.cveID = data.cveID;
            vul.vendorProject = data.vendorProject;
            if(vendorUserObject.some(e => e.vendorProject === data.vendorProject)){
                vendorsInSystem.push(vul);
            } else {
                vendorsToAdd.push(vul);
            }
        });

        // Check if cve already exists in system
        let cvesWorkedOn = userVendorCvesObject.filter(function(item) { return item.cveWorked === "true" });

        const vendorInSystemCveToAdd = vendorsInSystem.filter(({ cveID: id1 }) => !cvesWorkedOn.some(({ cveID: id2 }) => id2 === id1));

        console.log(vendorsToAdd);
        console.log(vendorInSystemCveToAdd);

        if(vendorsToAdd.length > 0){

            //Add vendors
            vendorsToAdd.map((data:any) => {
                insertVendorUser(data.vendorProject);
                insertLog("Vendor Added", `${data.vendorProject} | auto added from file: ${templateName}`);
            });


            //Add Cves from new vendors
            vendorsToAdd.map((data:any) => {
                cveAddInitial(data.vendorProject, data.cveID);
                insertLog("CVE Protection Change",`CVE: ${data.cveID} | auto update from file: ${templateName}`);
            });

            //Add Cves from vendors in system but cves not worked on
            vendorInSystemCveToAdd.map((data:any) => {
                cveAddInitial(data.vendorProject, data.cveID);
                insertLog("CVE Protection Change",`CVE: ${data.cveID} | auto update from file: ${templateName}`);
            });
        }

        if(vendorsToAdd.length < 1 && vendorInSystemCveToAdd.length<1){
            setFileUploadStatus("The uploaded " + templateName + " file contains " + fileVendorList.length + " cisa cves. Your vendors and cves are already up to date with them");
        } else {
            setFileUploadStatus("The uploaded " + templateName + " file contains " + fileVendorList.length + " cisa cves and "+ vendorsToAdd.length + " new vendors have been automatically added.");
        }
        getUserVendors(userObject?.userID == null ? "" : userObject?.userID);
    }



    }

    useEffect(() => {
        getUserVendors(userObject?.userID!);
    },[]);

    return (
        <>
            <h3 style={{marginTop: "30px"}}>Vendor Admin</h3>
            <Tabs
            defaultActiveKey="manual"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
            style={{marginTop: "20px"}}
            >
            <Tab eventKey="manual" title="Manual Vendor">
                
                <div id="vendors" className="tabcontent">
                    <h6>Manual Vendor Settings</h6>
                    <p>Your Vendors</p>
                    <div id="userVendors" className="userVendors">
                    {loadingUser ? <SpinnerLoader /> : ""}
                    {vendorUserObject == null ? "" : vendorUserObject?.map((vendor, index) => {
                        return (
                        <Button key={index} className="btn btn-success btn-outline-success vendorButton" onClick={()=> {removeVendor(vendor.vendorProject);}}>{vendor.vendorProject}</Button>
                        /*<Button key={index} className="btn btn-success btn-outline-success vendorButton" onClick={()=> {deleteVendorUser(vendor.vendorProject);}}>{vendor.vendorProject}</Button>*/
                        );
                    })}
                    </div>
                    <br />
                    <br />
                    <br />
                    <p style={{color:'black', borderTop: '1px solid #e0e0e0'}}>Vendor List</p>
                    <br />
                    <div id="vendorContainer" className="vendorContainer">
                    {loadingVendor ? <SpinnerLoader /> : ""}
                    {vendorObject == null ? "" : vendorObject?.map((vendor, index) => {
                        return (
                        <Button key={index} variant="outline-info" className="vendorButtonAdd" onClick={()=> {insertVendorUser(vendor.vendorProject); insertLog("Vendor Added", `${vendor.vendorProject}`);}}>{vendor.vendorProject}</Button>
                        );
                    })}

                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        <i className="fas fa-shield-alt" style={{marginRight: "5px"}}><FaIcons.FaTrash /></i>Remove Vendor
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to remove Vendor <b>{deleteVendor}</b> from your list?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={()=> {deleteVendorUser(deleteVendor);handleClose();}}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>


            </Tab>
            <Tab eventKey="upload" title="Vendor Cve File Upload">
                <div id="vendors" className="tabcontent">
                    <h6>Vendor CVE File Upload</h6>
                    <p style={{marginTop: "50px"}}>Select Your cve File Template:</p>

                    <div style={{width: "39%",float: "left"}}>
                        <DropdownButton size="sm" variant="secondary" className={"d-grid"} title={templateName} style={{width: "100%",float: "left"}}>
                            <Dropdown.Item onClick={() => setTemplateName("nessus")} eventKey='nessus'>Nessus</Dropdown.Item>
                            <Dropdown.Item onClick={() => setTemplateName("rapid7")} eventKey='rapid7'>Rapid7</Dropdown.Item>
                            <Dropdown.Item onClick={() => setTemplateName("qualys")} eventKey='qualys'>Qualys</Dropdown.Item>
                        </DropdownButton>
                    </div>

                    <div style={{width: "30%", float: "left", overflow: "hidden", marginLeft: "20px"}}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control size="sm"  type="file" accept={".csv"} onChange={fileSelected} />
                        </Form.Group>
                    </div>

                    <div style={{width: "12%", overflow: "hidden"}}>
                        <Button onClick={uploadFile} size="sm">Submit</Button>
                    </div>

                    <div style={{marginTop: "25px"}}>
                        <u>Status:</u>
                        <div style={{color: "red"}}>{fileUploadStatus}</div>
                    </div>

                </div>
            </Tab>
            </Tabs>



        </>

    )
}

export default Vendor
